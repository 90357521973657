import React, {useEffect} from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import stylesFormCard from "../FormCardComponent.module.scss";
import styles from "../unitStayRestrictionRule/UnitStyrestrictionRuleComponent.module.scss";
import {UnitStayRestrictionItemComponent} from "./ruleRestrictionItem/UnitRestrictionItemComponent";
import _ from "lodash";

interface UnitStayRestrictionRuleProps {
    unitRuleValue: UnitRuleValuesResponse;
    setUnitRuleValue: Function;
    disabled: boolean;
}

export const UnitStayRestrictionRuleComponent: React.FunctionComponent<UnitStayRestrictionRuleProps> = (props) => {
    const {setUnitRuleValue, disabled} = props;
    let {unitRuleValue} = props;

    useEffect(() => {
        (async () => {
            try {
                if (unitRuleValue.attributes?.unit_rule_value_attributes !== null && unitRuleValue.attributes.unit_rule_value_attributes["enrolled"] === undefined) {
                    unitRuleValue.attributes.unit_rule_value_attributes['enrolled'] = [];
                }
               unitRuleValue.attributes.unit_rule_value_attributes?.enrolled?.map(restriction => {
                   if (!restriction.statusUI) restriction.statusUI = 'lock'
               })
            } catch (error) {
                console.error(error);
            }
        })();
    }, [unitRuleValue]);

    const addRestriction = (type: string) => {
        const restriction = {
            restriction_type: type,
            restriction_number: 1,
            restriction_duration :null,
            restriction_duration_type: "year",
            restriction_effective_type: "always",
            statusUI: "open",
            isNew: true
        };

        const cloneUnitRuleValues = _.cloneDeep(unitRuleValue) as any;
        if(!cloneUnitRuleValues.attributes.unit_rule_value_attributes){
            cloneUnitRuleValues.attributes.unit_rule_value_attributes = {
                enrolled: []
            }
        }

        cloneUnitRuleValues.attributes.unit_rule_value_attributes?.enrolled?.push(restriction)
        setUnitRuleValue(cloneUnitRuleValues);
    };

    return (
        <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox}`}>
            <div className={"form-row"}>
                <div className="col">
                    <h6 className={`${styles.title}`}><strong>Restrictions</strong></h6>
                </div>
            </div>
            <div className="accordion mt-4" >
                {unitRuleValue.attributes.unit_rule_value_attributes?.enrolled?.map((restriction, index) => {
                    return <UnitStayRestrictionItemComponent unitRuleValue={unitRuleValue} item={restriction} index={index} setItem={setUnitRuleValue} key={`${unitRuleValue.id}-${index}`} disabled={disabled} />;
                })}
            </div>

            {!disabled
                ? (
                    <div className={styles.buttonsBox}>
                        <div className={`${styles.pillsButtonsCheckin} ${styles.btn}`} onClick={() => addRestriction("check-in")} >+ Add Check-ins Restriction</div>
                        <div className={`${styles.pillsButtonsNight} ${styles.btn}`} onClick={() => addRestriction("nights")}>+ Add Night Restriction</div>
                    </div>
                )
                : (<React.Fragment/>)}


        </div>
    )
}