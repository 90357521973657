import {authManager} from "@common/authentication";
import _ from "lodash";

export class AdminManagerService {
    params: URLSearchParams;

    constructor() {
        this.params = new URLSearchParams(window.location.search);
    }

    getUnitId = (): number => {
        const unitId: string = this.params.get("unitId");
        if (!_.isEmpty(unitId)) {
            return +unitId;
        }

        const info = authManager.getInfoFromAdmin<{user: string; unitId: number}>();
        return info.unitId;
    };

    getUnitDisplay(): boolean {
        const unitDisplay: string = this.params.get("unitDisplay");
        if (!_.isEmpty(unitDisplay)) {
            return +unitDisplay === 0 ? false : true;
        }

        const info = authManager.getInfoFromAdmin<{unit: any}>();
        return +info?.unit?.display === 0 ? false : true;
    }
}
