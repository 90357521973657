import React from "react";
import _ from "lodash";
import {PrincipalSelectComponent} from "./principalSelect/PrincipalSelectComponent"
import {SecondarySelectComponent} from "./secondarySelect/SecondarySelectComponent"
import {Value, RulesList, UnitRuleValuesResponse} from "../../../unitsResRules.type";

interface DynamicSelectsInput {
    selectedItem: UnitRuleValuesResponse
    setItem: Function;
    principal: string;
    secondary: string;
    principalListCollection: Array<RulesList>;
    secondListCollection: Array<Value>;
    disabledPrincipal: boolean;
    disabledSecondary: boolean;
    principalLabel: string;
    secondaryLabel: string;
}

export const DynamicSelectsComponent: React.FunctionComponent<DynamicSelectsInput> = (props) => {
    const { selectedItem, setItem, principal, secondary, principalListCollection, secondListCollection, disabledPrincipal, disabledSecondary, principalLabel, secondaryLabel } = props;

    const getDefaultValue = (code) => {
        let defaultValue;
        principalListCollection.forEach((x) => {
            if (x.attributes.code === code){
                if (x.attributes.default_value_code === null || x.attributes.default_value_code.match(/^ *$/) !== null){
                    defaultValue = x.attributes.values[0];
                } else {
                    x.attributes.values.forEach(y => {
                        if (y.code === x.attributes.default_value_code){
                            defaultValue = y;
                        }
                    });
                }
            }
        });
        return defaultValue;
    }

    const handleChange = async (property: string, e: any,) => {
        const target = e.target as HTMLInputElement;
        let itemClone = _.cloneDeep(selectedItem);

        if (property === principal){
            const newValue = setNewItemValue(target.value, principalListCollection, "code");
            principalValue = newValue["code"];
            _.set(itemClone.attributes, property, newValue);
            const defaultValue = getDefaultValue(principalValue);
            _.set(itemClone.attributes, secondary, defaultValue);
        } else {
            const newValue = setNewItemValue(target.value, secondListCollection, "code", false);
            _.set(itemClone.attributes, property, newValue);
        }
        setItem(itemClone, true);
    };

    const setNewItemValue = (value: string | string, list: any, attr: string, primary: boolean = true) => {
        let newItem;
        list.forEach((x) => {
            if (primary) {
                if (x.attributes[attr] === value ){
                    newItem = x.attributes;
                }
            } else {
                if ( x[attr] === value){
                    newItem = x;
                }
            }
        });
        return newItem;
    }

    let principalValue = selectedItem.attributes[principal].code;
    let secondaryValue = selectedItem.attributes[secondary]?.code ?? principalListCollection[0].attributes?.default_value_code;

    return (
        <div>
            <PrincipalSelectComponent
                selectedItem={selectedItem}
                principal={principal}
                principalValue={principalValue}
                handleChange={handleChange}
                principalListCollection={principalListCollection}
                disabled={disabledPrincipal}
                label={principalLabel}
            />
            <SecondarySelectComponent
                selectedItem={selectedItem}
                secondary={secondary}
                secondaryValue={secondaryValue}
                handleChange={handleChange}
                secondListCollection={secondListCollection}
                disabled={disabledSecondary}
                label={secondaryLabel}
            />
        </div>
    )
}