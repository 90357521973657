import React from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import {InputCalendarComponent} from "@common/react-components/lib/src/inputCalendar/InputCalendarComponent";
import {TextareaComponent} from "@common/react-components/lib/src/textarea/TextareaComponent";
import stylesFormCard from "../FormCardComponent.module.scss";
import styles from "./CompliancePermitRequiredRuleComponent.module.scss";
import {Input, Required, ThemeProvider} from '@vacasa/react-components-lib';
import * as _ from "lodash";
import _default from "@vacasa/react-components-lib/lib/themes/default";

interface CompliancePermitRequiredRuleProps {
    item: UnitRuleValuesResponse;
    setItem: Function;
}

export const CompliancePermitRequiredRuleComponent: React.FunctionComponent<CompliancePermitRequiredRuleProps> = (props) => {
    const {setItem} = props;
    let {item} = props;

    if (item.attributes.unit_rule_value_attributes === null) {
        item.attributes.unit_rule_value_attributes = {
            number_permits_req: 1,
            override_requirement: false,
            override_effective_date: null,
            override_end_date: null,
            override_notes: null,
        }
    }

    const handleInputNumberChange = (e) => {
        let ruleClone = _.cloneDeep(item);
        ruleClone.attributes.unit_rule_value_attributes.number_permits_req = e;
        setItem(ruleClone, true);
    }

    const handleCheckboxChange = (e) => {
        let ruleClone = _.cloneDeep(item);
        ruleClone.attributes.unit_rule_value_attributes.override_requirement = !item.attributes.unit_rule_value_attributes?.override_requirement;
        setItem(ruleClone, true);
    }

    const handleComplianceNotesChange = (updatedItem, edited) => {
        let ruleClone = _.cloneDeep(item);
        ruleClone.attributes.unit_rule_value_attributes.override_notes = updatedItem.override_notes;
        setItem(ruleClone, edited);
    }

    const handleComplianceDatesChange = (updatedItem, edited) => {
        let ruleClone = _.cloneDeep(item);
        if (updatedItem.hasOwnProperty("start_date"))
            ruleClone.attributes.unit_rule_value_attributes.override_effective_date = updatedItem.start_date;
        if (updatedItem.hasOwnProperty("end_date"))
            ruleClone.attributes.unit_rule_value_attributes.override_end_date = updatedItem.end_date !== "" ? updatedItem.end_date : null;
        setItem(ruleClone, edited);
    }

    const requiredField = <ThemeProvider theme={_default}>
        <Required
            type={"error"}
            size={"small"}
            text={"*"}
        ></Required>
    </ThemeProvider>

    const requiredFieldWithText = <ThemeProvider theme={_default}>
        <Required
          type={"error"}
          size={"small"}
          text={"* 25 char min"}
        ></Required>
    </ThemeProvider>

    return (
        <div className={`${stylesFormCard.littleFont} ${stylesFormCard.ruleItem} ${styles.complianceBox}`}>
            <div className={"form-row"}>
                <div className="col">
                    <h6 className={`${styles.title}`}><strong>Compliance Permits specific attributes</strong></h6>
                </div>
            </div>
            <div className={"form-row"}>
                <div className={`col ${styles.customRow}`}>
                    <div className={"col-sm-8"} style={{paddingLeft: 0}}>
                        <label><strong>Number of Permits Required</strong></label>
                    </div>
                    <div className={"col-sm-4"} style={{paddingRight: 0}}>
                        <ThemeProvider theme={_default}>
                            <Input
                                type="number"
                                value={item.attributes.unit_rule_value_attributes?.number_permits_req ?? 1}
                                onChange={e => handleInputNumberChange(+e.target.value)}
                                disabled={false}
                                customClass={` ${styles.whiteBackground}`}
                                min={1}
                                max={5}
                            />
                        </ThemeProvider>
                    </div>
                </div>
                <div className="col">
                    <label className={"right"}><strong>Override Requirement</strong></label>
                    <input
                        type={"checkbox"}
                        name={"override_requirement"}
                        id={"override_requirement"}
                        className={`${styles.customCheckbox} right`}
                        onChange={handleCheckboxChange}
                        defaultChecked={item.attributes.unit_rule_value_attributes?.override_requirement}
                        title={"Overriding this rule will allow a unit to be displayed even if it fails the permit check. Meant for temporary use only"}
                    />
                </div>
            </div>

            {item.attributes.unit_rule_value_attributes?.override_requirement ?
                <React.Fragment>
                    <InputCalendarComponent
                        selectedItem={item.attributes.unit_rule_value_attributes}
                        id={`override_${item.index}`}
                        start_date={item.attributes.unit_rule_value_attributes?.override_effective_date}
                        end_date={item.attributes.unit_rule_value_attributes?.override_end_date ?? ""}
                        readOnlyStartDate={false}
                        readOnlyEndDate={false}
                        minStartDate={null}
                        maxStartDate={item.attributes.unit_rule_value_attributes?.override_end_date}
                        minEndDate={item.attributes.unit_rule_value_attributes?.override_effective_date}
                        setItem={handleComplianceDatesChange}
                        startDateLabel={"Override Effective Date"}
                        endDateLabel={"Override End Date"}
                        requiredStartDate={requiredField}
                        requiredEndtDate={requiredField}
                    />

                    <TextareaComponent
                        selectedItem={item.attributes.unit_rule_value_attributes}
                        text={item.attributes?.unit_rule_value_attributes?.override_notes}
                        id={`override_notes_${item.index}`}
                        name={"override_notes"}
                        placeholder={""}
                        minLenght={20}
                        maxLength={250}
                        readOnly={ false}
                        labelName= {"Override Notes"}
                        setItem={handleComplianceNotesChange}
                        disabled={false}
                        required={requiredFieldWithText}
                    />
                </React.Fragment>
            : <React.Fragment/>
            }


        </div>
    )
}