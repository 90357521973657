import React from "react";
import "./App.css";
import RouterApp from "./router/Router";
import {ToastProvider} from "react-toast-notifications";
import {authManager} from "@common/authentication";

function App() {
    const info = authManager.getInfoFromAdmin();

    return (
        info['canView'] ?
            <div className="App">
                <ToastProvider autoDismiss autoDismissTimeout={5000} placement="bottom-center">
                    <RouterApp/>
                </ToastProvider>
            </div>
            :
            <React.Fragment/>


    );
}

export default App;