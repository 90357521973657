import React, {useEffect} from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import stylesFormCard from "../FormCardComponent.module.scss";
import styles from "./SeasonalRatesRuleComponent.module.scss";
import {SeasonalRatesRuleItemComponent} from "./SeasonalRatesRuleItemComponent";
import _ from "lodash";
import {RULE_CODE} from "../../../../constants";

interface SeasonalRulesProps {
    unitRuleValue: UnitRuleValuesResponse;
    setUnitRuleValue: Function;
    disabled: boolean;
    currency: string;
}

export const SeasonalRulesComponent: React.FunctionComponent<SeasonalRulesProps> = (props) => {
    const {setUnitRuleValue, disabled, currency} = props;
    let {unitRuleValue} = props;

    const rule = unitRuleValue.attributes.rule.code;
    let title = "";
    switch (rule) {
        case RULE_CODE.SEASONAL_RATES: {
            title = "Seasonal Rates";
            break;
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (unitRuleValue.attributes?.unit_rule_value_attributes !== null && unitRuleValue.attributes?.unit_rule_value_attributes["enrolled"] === undefined) {
                    unitRuleValue.attributes.unit_rule_value_attributes['enrolled'] = [];
                }
                unitRuleValue.attributes.unit_rule_value_attributes?.enrolled?.map((record) => {
                    if (!record.statusUI) record.statusUI = "lock";
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }, [unitRuleValue]);

    const addRestriction = (rateType: string, rule: string) => {
        let restriction = {};
        switch (rule) {
            case RULE_CODE.SEASONAL_RATES: {
                restriction = {
                    rate_type: rateType,
                    reason_why: "",
                    weekday_rate: 1,
                    weekend_rate: 1,
                    rate_effective_start_date: null,
                    rate_effective_end_date: null,
                    statusUI: "open",
                    isNew: true,
                };
                break;
            }
        }

        const cloneUnitRuleValues = _.cloneDeep(unitRuleValue) as any;
        if (!cloneUnitRuleValues.attributes.unit_rule_value_attributes) {
            cloneUnitRuleValues.attributes.unit_rule_value_attributes = {
                enrolled: [],
            };
        }

        cloneUnitRuleValues.attributes.unit_rule_value_attributes?.enrolled?.push(restriction);
        setUnitRuleValue(cloneUnitRuleValues);
    };

    return (
        <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox}`}>
            <div className={"form-row"}>
                <div className="col">
                    <h6 className={`${styles.title}`}>
                        <strong>{title}</strong>
                    </h6>
                </div>
            </div>
            <div className="accordion mt-4">
                {unitRuleValue.attributes.unit_rule_value_attributes?.enrolled?.map((restriction, index) => {
                    switch (rule) {
                        case RULE_CODE.SEASONAL_RATES: {
                            return (
                                <SeasonalRatesRuleItemComponent
                                    unitRuleValue={unitRuleValue}
                                    item={restriction}
                                    index={index}
                                    setItem={setUnitRuleValue}
                                    key={`${unitRuleValue.id}-${index}`}
                                    disabled={disabled}
                                    currency={currency}
                                />
                            );
                            break;
                        }
                    }
                })}
            </div>

            {!disabled ? (
                <div className={styles.buttonsBox}>
                    <div className={`${styles.pillsMinRateButtons} ${styles.btn}`} onClick={() => addRestriction("min_rate", rule)}>
                        + Add Seasonal Min Rates
                    </div>
                </div>
            ) : (
                <React.Fragment />
            )}
        </div>
    );
};
