import React, { useState, useEffect } from "react";
import {RulesList, UnitRuleValuesResponse} from "../../unitsResRules.type";
import _ from "lodash";
import moment from "moment";
import styles from "./FormCardComponent.module.scss";
import {CollapseButtonComponent} from "./collapseButton/CollapseButtonComponent";
import {RemoveButtonComponent} from "./removeButton/RemoveButtonComponent";
import {DynamicSelectsComponent} from "./dynamicSelects/DynamicSelectsComponent";
import {TextareaComponent} from "@common/react-components";
import {InputCalendarComponent} from "@common/react-components";
import {CompliancePermitRequiredRuleComponent} from "./compliancePermitRequiredRule/CompliancePermitRequiredRuleComponent";
import {CMPLNC_PERMITS_STANDARD_VALUE, RULE_CODE} from "../../../constants";
import {Required, ThemeProvider} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {UnitStayRestrictionRuleComponent} from "./unitStayRestrictionRule/UnitStayRestrictionRuleComponent";
import { CheckInRuleComponent } from "./checkInRule/CheckInRuleComponent";
import { SeasonalRulesComponent } from "./seasonalRatesRule/SeasonalRulesComponent";

interface RulesInput {
    unitRuleValue: UnitRuleValuesResponse
    setFormItem: Function;
    principalListCollection: Array<RulesList>;
    getValues: Function;
    currency: string;
}

export const FormCardComponent: React.FunctionComponent<RulesInput> = (props) => {
    const { unitRuleValue, setFormItem, principalListCollection, getValues, currency } = props;
    const [secondListCollection, setSecondListCollection] = useState([]);

    const formatDate = (date) => {
        if (_.isNil(date))
            return "-";
        return moment(date).format("MM/DD/YYYY");
    }

    const disabledSelect = !!unitRuleValue.id;
    const today = moment().format("YYYY-MM-DD");
    const disabledForm = !_.isNil(unitRuleValue.attributes.end_date) && !unitRuleValue.created && !unitRuleValue.edited ? unitRuleValue.attributes.end_date < today : false;

    const requiredField = <ThemeProvider theme={_default}>
        <Required
            type={"error"}
            size={"small"}
            text={"*"}
        ></Required>
    </ThemeProvider>

    useEffect(() => {
        (async () => {
            try {
                const valuesResponse = await getValues(unitRuleValue.attributes.rule.code ?? principalListCollection[0]?.attributes?.code);
                if (!_.isEmpty(valuesResponse)) {
                    setSecondListCollection(valuesResponse[0].attributes.values);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [unitRuleValue]);

    const lockForm = (
        <div className={styles.ruleItem}>
            <div className={styles.lock}>
                <div className="col-sm-9">
                     <div className="form-row">
                        <span>
                            <strong>{unitRuleValue.attributes.rule?.name ?? ""}</strong>
                        </span>
                    </div>
                    <div className="form-row">
                        <span>{unitRuleValue.attributes.value?.code ?? ""}</span>
                    </div>
                    <div className="form-row">
                        <span className={styles.detailsFont}><strong>Effective date:</strong> {formatDate(unitRuleValue.attributes.start_date)} - <strong>End Date:</strong> {formatDate(unitRuleValue.attributes.end_date)}</span>
                    </div>
                </div>
                <div className="center col-sm-3">
                    <RemoveButtonComponent item={unitRuleValue} setFormCard={setFormItem} disabled={false}/>
                    <CollapseButtonComponent item={unitRuleValue} setFormCard={setFormItem} open={unitRuleValue.statusUI === "open" ? true : false}/>
                </div>
            </div>
        </div>
    );

    const openForm = (
        <div className={styles.ruleItem}>
             <div className={`row ${styles.headerTools}`}>
                 {!disabledForm ?
                     <div className="col">
                         <ThemeProvider theme={_default}>
                             <Required
                                 type={"error"}
                                 size={"small"}
                                 text={"Required (*)"}
                                 customClass={styles.requiredLabel}
                             ></Required>
                         </ThemeProvider>
                     </div>
                 : <React.Fragment/>
                 }
                <div className="col">
                    <RemoveButtonComponent item={unitRuleValue} setFormCard={setFormItem} disabled={false}/>
                    <CollapseButtonComponent item={unitRuleValue} setFormCard={setFormItem} open={unitRuleValue.statusUI === "open" ? true : false}/>
                </div>
            </div>

            <form>
                <DynamicSelectsComponent
                    selectedItem={unitRuleValue}
                    setItem={setFormItem}
                    principal={"rule"}
                    secondary={"value"}
                    principalListCollection={principalListCollection}
                    secondListCollection={secondListCollection}
                    disabledPrincipal={disabledForm ? true : disabledSelect}
                    disabledSecondary={disabledForm ? true : disabledSelect}
                    principalLabel={"Rule Name"}
                    secondaryLabel={"Value"}
                />

                {unitRuleValue.attributes.rule.code === RULE_CODE.RESERVATION_CANCELATION  ? (
                    <div className={`${styles.littleFont} form-row ${styles.customMarginLeft}`} >
                        <div className={"form-group"}>
                            <label><strong>Days till Check-in: {unitRuleValue.attributes.value?.rule_value_attributes?.days_till_checkin}</strong></label>
                            <div className={"form-group"}>
                                <label><strong>Refound Amounts:</strong></label>
                                <p>% of Rent: {unitRuleValue.attributes.value?.rule_value_attributes?.percent_of_rent}</p>
                                <p>% of Booking fee: {unitRuleValue.attributes.value?.rule_value_attributes?.percent_of_booking_fee}</p>
                            </div>
                            <div className={"form-group"}>
                                <label><strong>Vacasa.com Guest Description:</strong></label>
                                <p>{unitRuleValue.attributes.value?.rule_value_attributes?.copy}</p>
                            </div>
                        </div>

                    </div>
                ) :

                    <div className={`${styles.littleFont} form-row ${styles.customMarginLeft}`} >
                        <div className={"form-group"}>
                            <label><strong>Description:</strong></label>
                            <p>{unitRuleValue.attributes.value?.description}</p>
                        </div>
                    </div>

                }

                {unitRuleValue.attributes.rule.code === RULE_CODE.CMPLNC_PERMITS && unitRuleValue.attributes.value.code !== CMPLNC_PERMITS_STANDARD_VALUE
                ? (
                    <CompliancePermitRequiredRuleComponent item={unitRuleValue} setItem={setFormItem}/>
                )
                : (<React.Fragment/>)}

                {unitRuleValue.attributes.rule.code === RULE_CODE.UNIT_STAY_RESTRICTIONS
                    ? (
                        <UnitStayRestrictionRuleComponent unitRuleValue={unitRuleValue} setUnitRuleValue={setFormItem} disabled={disabledForm}/>
                    )
                    : (<React.Fragment/>)}

                {unitRuleValue.attributes.rule.code === RULE_CODE.SEASONAL_RATES
                  ? (
                    <SeasonalRulesComponent unitRuleValue={unitRuleValue} setUnitRuleValue={setFormItem} disabled={disabledForm} currency={currency}/>
                  )
                  : (<React.Fragment/>)}

                {unitRuleValue.attributes.rule.code === RULE_CODE.CHECK_IN_REQUIREMENTS
                  ? (
                    <CheckInRuleComponent unitRuleValue={unitRuleValue} setItem={setFormItem}/>
                  )
                  : (<React.Fragment/>)}

                 <InputCalendarComponent
                    selectedItem={unitRuleValue}
                    id={unitRuleValue.index}
                    start_date={unitRuleValue.attributes.start_date}
                    end_date={unitRuleValue.attributes.end_date ?? ""}
                    readOnlyStartDate={disabledForm}
                    readOnlyEndDate={disabledForm ? true : !_.isNil(unitRuleValue.attributes.end_date) && !unitRuleValue.created && !unitRuleValue.edited ? unitRuleValue.attributes.end_date < today : false}
                    minStartDate={null}
                    maxStartDate={unitRuleValue.attributes.end_date}
                    minEndDate={unitRuleValue.attributes.start_date}
                    setItem={setFormItem}
                    startDateLabel={"Effective Date"}
                    endDateLabel={"End Date"}
                    requiredStartDate={requiredField}
                 />

                <TextareaComponent
                    selectedItem={unitRuleValue}
                    text={unitRuleValue.attributes?.notes}
                    id={`notes_${unitRuleValue.index}`}
                    name={"notes"}
                    placeholder={""}
                    maxLength={250}
                    readOnly={ false}
                    labelName= {"Notes"}
                    setItem={setFormItem}
                    disabled={disabledForm}
                    required ={requiredField}
                />

                {!_.isNil(unitRuleValue.id) ?
                    <div>
                        <div className={`${styles.littleFont} form-row`}>
                            <div className="col">
                                <div >
                                    <label><strong>Created at</strong> {unitRuleValue.attributes.created_at}</label>
                                </div>
                            </div>
                            <div className="col">
                                <div >
                                    <label><strong>Created by</strong> {unitRuleValue.attributes.created_by}</label>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.littleFont} form-row`}>
                            <div className="col">
                                <div >
                                    <label><strong>Modified at</strong> {unitRuleValue.attributes.modified_at}</label>
                                </div>
                            </div>
                            <div className="col">
                                <div >
                                    <label><strong>Modified by</strong> {unitRuleValue.attributes.modified_by}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                : <div></div>
                }
            </form>
        </div>
    )

    return unitRuleValue.statusUI === "open" ? openForm : lockForm;
};
