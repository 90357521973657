import React from 'react';
import {UnitRuleValuesResponse} from "../../unitsResRules.type";

interface UnitRuleValuesProps {
    unitRuleValue: UnitRuleValuesResponse;
    key?: number;
}

export const UnitRuleValuesSummary: React.FunctionComponent<UnitRuleValuesProps> = (props) => {
    const {unitRuleValue} = props;
    return (
        <tr key={unitRuleValue.id}>
            <th scope="col"></th>
            <th className="align-content-lg-end" scope="row">
                <strong>{unitRuleValue.attributes?.rule?.code}</strong>
            </th>
            <td><strong>{unitRuleValue.attributes?.value?.code}</strong></td>

        </tr>
    );
};
