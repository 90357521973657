import React from "react";
import {UnitRuleValuesResponse} from "../../unitsResRules.type";
import {CollapseButtonComponent} from "../formCard/collapseButton/CollapseButtonComponent";
import {CMPLNC_PERMITS_STANDARD_VALUE, RULE_CODE} from "../../../constants";
import _ from "lodash";
import moment from "moment";
import stylesFormCard from "../formCard/FormCardComponent.module.scss";
import stylesCompliance from "../formCard/compliancePermitRequiredRule/CompliancePermitRequiredRuleComponent.module.scss";
import styles from "../formCard/FormCardComponent.module.scss";

interface RulesInput {
    unitRuleValue: UnitRuleValuesResponse
    setFormItem: Function;
}

export const ViewCardComponent: React.FunctionComponent<RulesInput> = (props) => {
    const {unitRuleValue, setFormItem} = props;

    const formatDate = (date) => {
        if (_.isNil(date))
            return "-";
        return moment(date).format("MM/DD/YYYY");
    }

    const lockForm = (
        <div className={stylesFormCard.ruleItem}>
            <div className={stylesFormCard.lock}>
                <div className="col-sm-9">
                    <div className="form-row">
                        <span>
                            <strong>{unitRuleValue.attributes.rule?.name ?? ""}</strong>
                        </span>
                    </div>
                    <div className="form-row">
                        <span>{unitRuleValue.attributes.value?.code ?? ""}</span>
                    </div>
                    <div className="form-row">
                        <span className={stylesFormCard.detailsFont}><strong>Effective date:</strong> {formatDate(unitRuleValue.attributes.start_date)} - <strong>End Date:</strong> {formatDate(unitRuleValue.attributes.end_date)}</span>
                    </div>
                </div>
                <div className="center col-sm-3">
                    <CollapseButtonComponent item={unitRuleValue} setFormCard={setFormItem} open={unitRuleValue.statusUI === "open" ? true : false}/>
                </div>
            </div>
        </div>
    );

    const openForm = (
        <div className={stylesFormCard.ruleItem}>
            <div className={`row ${stylesFormCard.headerTools}`}>
                <div className="col">
                    <CollapseButtonComponent item={unitRuleValue} setFormCard={setFormItem} open={unitRuleValue.statusUI === "open" ? true : false}/>
                </div>
            </div>

            <div className={`form-row`} >
                <div className={"col"}>
                    <div className={"form-group"}>
                        <label><strong>Rule Name:</strong></label>
                        <p>{unitRuleValue.attributes.rule?.name ?? ""}</p>
                    </div>
                </div>
                <div className={"col"}>
                    <div className={"form-group"}>
                        <label><strong>Value:</strong></label>
                        <p>{unitRuleValue.attributes.value?.code ?? ""}</p>
                    </div>
                </div>
            </div>
            {unitRuleValue.attributes.rule.code === RULE_CODE.RESERVATION_CANCELATION  ? (
                    <div className={`${styles.littleFont} form-row ${styles.customMarginLeft}`} >
                        <div className={"form-group"}>
                            <label><strong>Days till Check-in: {unitRuleValue.attributes.value?.rule_value_attributes?.days_till_checkin}</strong></label>
                            <div className={"form-group"}>
                                <label><strong>Refound Amounts:</strong></label>
                                <p>% of Rent: {unitRuleValue.attributes.value?.rule_value_attributes?.percent_of_rent}</p>
                                <p>% of Booking fee: {unitRuleValue.attributes.value?.rule_value_attributes?.percent_of_booking_fee}</p>
                            </div>
                            <div className={"form-group"}>
                                <label><strong>Vacasa.com Guest Description:</strong></label>
                                <p>{unitRuleValue.attributes.value?.rule_value_attributes?.copy}</p>
                            </div>
                        </div>

                    </div>
                ) :

                <div className={`${styles.littleFont} form-row ${styles.customMarginLeft}`} >
                    <div className={"form-group"}>
                        <label><strong>Description:</strong></label>
                        <p>{unitRuleValue.attributes.value?.description}</p>
                    </div>
                </div>

            }

            {unitRuleValue.attributes.rule.code === RULE_CODE.CMPLNC_PERMITS && unitRuleValue.attributes.value.code !== CMPLNC_PERMITS_STANDARD_VALUE
                ?
                <div className={`${stylesFormCard.littleFont} ${stylesFormCard.ruleItem} ${stylesCompliance.complianceBox}`}>
                    <div className={"form-row"}>
                        <div className="col">
                            <h6 className={`${stylesCompliance.title}`}><strong>Compliance Permits specific attributes</strong></h6>
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <div className={`col ${stylesCompliance.customRow}`}>
                            <div className={"col-sm-8"} style={{paddingLeft: 0}}>
                                <label><strong>Number of Permits Required</strong></label>
                            </div>
                            <div className={"col-sm-4"} style={{paddingRight: 0}}>
                                <p>{unitRuleValue.attributes.unit_rule_value_attributes?.number_permits_req}</p>
                            </div>
                        </div>
                        <div className="col">
                            <label className={"right"}><strong>Override Requirement</strong></label>
                            <input
                                type={"checkbox"}
                                name={"override_requirement"}
                                id={"override_requirement"}
                                className={`${stylesCompliance.customCheckbox} right`}
                                checked={unitRuleValue.attributes.unit_rule_value_attributes?.override_requirement}
                                title={"Overriding this rule will allow a unit to be displayed even if it fails the permit check. Meant for temporary use only"}
                                disabled={true}
                            />
                        </div>
                    </div>

                    {unitRuleValue.attributes.unit_rule_value_attributes?.override_requirement ?
                        <React.Fragment>
                            <div className={`form-row`} >
                                <div className={"col"}>
                                    <div className={"form-group"}>
                                        <label><strong>Override Effective Date:</strong></label>
                                        <p>{unitRuleValue.attributes.unit_rule_value_attributes?.override_effective_date}</p>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div className={"form-group"}>
                                        <label><strong>Override End Date:</strong></label>
                                        <p>{unitRuleValue.attributes.unit_rule_value_attributes?.override_end_date ?? "N/A"}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={`${stylesFormCard.littleFont} form-row`} >
                                <div className={"form-group"}>
                                    <label><strong>Override Notes:</strong></label>
                                    <p>{unitRuleValue.attributes?.unit_rule_value_attributes?.override_notes}</p>
                                </div>
                            </div>
                        </React.Fragment>
                        : <React.Fragment/>
                    }


                </div>

                : <React.Fragment/>}

            <div className={`form-row`} >
                <div className={"col"}>
                    <div className={"form-group"}>
                        <label><strong>Effective Date:</strong></label>
                        <p>{unitRuleValue.attributes.start_date}</p>
                    </div>
                </div>
                <div className={"col"}>
                    <div className={"form-group"}>
                        <label><strong>End Date:</strong></label>
                        <p>{unitRuleValue.attributes.end_date ?? "N/A"}</p>
                    </div>
                </div>
            </div>

            {!_.isNil(unitRuleValue.id) ?
                <div>
                    <div className={`${stylesFormCard.littleFont} form-row`}>
                        <div className="col">
                            <div >
                                <label><strong>Created at</strong> {unitRuleValue.attributes.created_at}</label>
                            </div>
                        </div>
                        <div className="col">
                            <div >
                                <label><strong>Created by</strong> {unitRuleValue.attributes.created_by}</label>
                            </div>
                        </div>
                    </div>

                    <div className={`${stylesFormCard.littleFont} form-row`}>
                        <div className="col">
                            <div >
                                <label><strong>Modified at</strong> {unitRuleValue.attributes.modified_at}</label>
                            </div>
                        </div>
                        <div className="col">
                            <div >
                                <label><strong>Modified by</strong> {unitRuleValue.attributes.modified_by}</label>
                            </div>
                        </div>
                    </div>
                </div>
                : <div></div>
            }
        </div>
    )

    return unitRuleValue.statusUI === "open" ? openForm : lockForm;
}