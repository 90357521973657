import React, { useEffect, useState } from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import stylesFormCard from "../FormCardComponent.module.scss";
import styles from "./CheckInRuleCmponent.module.scss";
import { Checkbox, Required, ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import _ from "lodash";

interface CheckInRuleProps {
    unitRuleValue: UnitRuleValuesResponse;
    setItem: Function;
}

export const CheckInRuleComponent: React.FunctionComponent<CheckInRuleProps> = (props) => {
    let {unitRuleValue, setItem} = props;
    const [checkedFaceToFace, setCheckedFaceToFace] = useState(true);

  const handleCheckboxChange = (name, checked) => {
    let ruleClone = _.cloneDeep(unitRuleValue);
    setCheckedFaceToFace(checked);
    ruleClone.attributes.unit_rule_value_attributes[name] = checked;
    setItem(ruleClone, true);
  }

    useEffect(() => {
        (async () => {
            try {
               if (unitRuleValue.attributes.unit_rule_value_attributes == null) {
                 setCheckedFaceToFace(true);
                 unitRuleValue.attributes.unit_rule_value_attributes = {
                   face_to_face_checkin_required: checkedFaceToFace
                 };
               } else {
                 setCheckedFaceToFace(unitRuleValue.attributes.unit_rule_value_attributes.face_to_face_checkin_required);
               }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [unitRuleValue]);

    return (
        <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.checkInBox}`}>
            <div className={"form-row"}>
                <div className="col">
                    <h6 className={`${styles.title}`}><strong>Requirements</strong></h6>
                </div>
            </div>

          <div className={"form-row"} >
              <ThemeProvider theme={_default}>
                <Checkbox
                  label="Face to Face Check-in Required"
                  type="info"
                  disabled={false}
                  checked={checkedFaceToFace}
                  onChange={() => handleCheckboxChange("face_to_face_checkin_required", !checkedFaceToFace)}
                />
                <Required
                  text={`* Must be checked`}
                  type={"error"}
                  size={"small"}
                ></Required>
              </ThemeProvider>
          </div>
        </div>
    )
}