import React, {useState, useEffect} from 'react';
import {authManager} from '@common/authentication';
import styles from './UnitRuleValuesPreviewApp.module.scss';
import {HeaderSummary} from './headerSummary/HeaderSummary';
import {UnitRuleValuesResponse} from "../unitsResRules.type";
import {ContentSummary} from './contentSummary/ContentSummary';
import _ from 'lodash';
import {RulesService} from "@common/units-api/lib/src/rules.service";


interface UnitRuleValuesPreviewProps {}

export const UnitRuleValuesPreviewApp: React.FunctionComponent<UnitRuleValuesPreviewProps> = (props) => {
    const [unitRuleValues, setUnitRuleValues] = useState<Array<UnitRuleValuesResponse>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const getRule = (unitRuleValue, rules) => {
        if(rules){
            let ruleResponse = {};
            for (const rule of rules) {
                if (rule.id.toString() == unitRuleValue.attributes.rule_id){
                    ruleResponse = rule.attributes;
                    break;
                }
            }
            return ruleResponse;
        }
    };

    const getValue = (unitRuleValue, values) => {
        if(values){
            let valueResponse = '';
            for (const value of values) {
                if (value.id.toString() == unitRuleValue.attributes.value_id){
                    valueResponse = value.attributes;
                    break;
                }
            }

            return valueResponse;
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (!_.isEmpty(unitRuleValues)) return;
                const unitService = new RulesService(authManager.getJwt()),
                info = authManager.getInfoFromAdmin<{ user: string; unitId: number }>(),
                uuid = await unitService.getUuidByLegacyUnitIdV2(info.unitId);
                const response  = await unitService.getActiveUnitRuleValues(uuid);
                let unitRuleValuesResponse =  response.data;

                if (!_.isNil(response.included)) {

                    response.included.filter(element => element.type === 'rule');
                    const rulesIncluded =  response.included.filter(element => element.type === 'rule');
                    const valuesIncluded = response.included.filter(element => element.type === 'rule_value');

                    unitRuleValuesResponse = unitRuleValuesResponse.map(unitRuleValue => {
                        return {
                            ...unitRuleValue,
                            attributes: {
                                ...unitRuleValue.attributes,
                                rule: getRule(unitRuleValue, rulesIncluded),
                                value: getValue(unitRuleValue, valuesIncluded)
                            }

                        } as UnitRuleValuesResponse
                    })
                }

                if (!_.isNil(unitRuleValuesResponse)) {
                    unitRuleValuesResponse = _.sortBy(unitRuleValuesResponse, ['attributes.rule.code']);
                    setUnitRuleValues(unitRuleValuesResponse);
                }

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    //resize iframe for each render
    useEffect(() => {
        const height = document.getElementById('root').clientHeight;
        window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
    });

    return (
        <div className={`${styles.summaryApp}`}>
            {isLoading ? (
                <div className="row">
                    <div className={`col ${styles.centerContent}`}>
                        <h6>Loading data...</h6>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="container-fluid">
                        <div className="row">
                            <HeaderSummary />
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto">
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <ContentSummary unitRuleValues={unitRuleValues} />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
