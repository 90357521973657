import React from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import _ from "lodash";
import {Icon} from '@vacasa/react-components-lib';
import styles from "./RemoveButtonComponent.module.scss";

interface RemoveButtonInput {
    item: UnitRuleValuesResponse;
    setFormCard: Function;
    disabled: boolean;
}

export const RemoveButtonComponent: React.FunctionComponent<RemoveButtonInput> = (props) => {
    const {item, setFormCard, disabled} = props;

    const handleButtonDelete = () => {
        const cloneItem = _.cloneDeep(item);
        cloneItem.deleted = true;
        setFormCard(cloneItem);
    };

    return ( <button type="button" className={styles.removeBtn} onClick={handleButtonDelete} disabled={disabled}><Icon.Trash2/></button> )
}