import React from "react";
import { SeasonalRatesValue, UnitRuleValuesResponse } from "../../../unitsResRules.type";
import styles from "./SeasonalRatesRuleComponent.module.scss";
import { AccordionItemComponent } from "../accordionItem/AccordionItemComponent";
import stylesFormCard from "../FormCardComponent.module.scss";
import { AlertMessage, Icon, Input, Required, ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import _ from "lodash";
import {useToasts} from "react-toast-notifications";

interface SeasonalRatesRuleItemProps {
  unitRuleValue: UnitRuleValuesResponse;
  item: SeasonalRatesValue;
  setItem: Function;
  index: number;
  disabled: boolean;
  currency: string
}

export const SeasonalRatesRuleItemComponent: React.FunctionComponent<SeasonalRatesRuleItemProps> = (props) => {
  const {addToast} = useToasts();
  const {setItem, unitRuleValue,index, disabled, currency} = props;
  let {item} = props;
  let hasError = item.errors?.length>0;

  const getRestrictionName = () => {
    switch (item["rate_type"]) {
      case "min_rate": {
        return "MinRate Seasonality"
        break;
      }
    }
  };

  const headerInfo = <React.Fragment>{currency} {item.weekday_rate} / {currency} {item.weekend_rate} | {item.rate_effective_start_date} {hasError ? <Icon.AlertOctagon className={styles.headerInfoWarning} height={24} width={24}/> : ""}</React.Fragment>;

  const getAccordionHeader = () => {
    return (
      <div className={`form-row  ${styles.unitStayBoxHeader}`}>
        <AccordionItemComponent unitRuleValue={unitRuleValue} index={index}  item={item} setFormCard={setItem} open={item.statusUI === "open" ? true : false} disabled={disabled} getRestrictionName={getRestrictionName} headerInfo={headerInfo}/>
      </div>
    )
  }

  const lockFor = (
    <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox} ${styles.seasonalBox}`}>
      {getAccordionHeader()}
    </div>
  )

  const handleInputNumberChange = (name, e) => {
    let ruleClone = _.cloneDeep(unitRuleValue);
    if (e < 1) e = 1
    e = parseInt(e.toString().replace(".", ""))
    if (+e > 9223372036854776000 ) {
      addToast(`${name} the field has a very high value`, {appearance: "warning"});
      e = ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name];
    }
    ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e
    setItem(ruleClone, true);
  }

  const handleInputChange = (name, e) => {
    let ruleClone = _.cloneDeep(unitRuleValue);
    if ((name === 'rate_effective_end_date' || name === 'rate_effective_start_date') && e.length === 0)
      e = null;
    ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e;
    setItem(ruleClone, true);
  }

  const handleSelectChange = (name, e) => {
    let ruleClone = _.cloneDeep(unitRuleValue);
    ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e;
    setItem(ruleClone, true);
  }

  const requiredField = <ThemeProvider theme={_default}>
    <Required
      type={"error"}
      size={"small"}
      text={"*"}
    ></Required>
  </ThemeProvider>

  const openFor = (
    <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox} ${styles.seasonalBox}`}>
      {getAccordionHeader()}
      <div className={"form-row"}>
        <div className={`col-sm-12 ${styles.customRow}`}>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <label>Weekday Rate{requiredField}</label>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <ThemeProvider theme={_default}>
              <Input
                type="number"
                value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].weekday_rate || 1}
                onChange={e => handleInputNumberChange("weekday_rate",+e.target.value)}
                disabled={disabled}
                customClass={` ${styles.whiteBackground} ${styles.numberInput}`}
                min={1}
                max={9223372036854776000}
                decoration={currency ?? ""}
              />
            </ThemeProvider>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <label>Weekend Rate{requiredField}</label>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <ThemeProvider theme={_default}>
            <Input
              type="number"
              value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].weekend_rate || 1}
              onChange={e => handleInputNumberChange("weekend_rate",+e.target.value)}
              disabled={disabled}
              customClass={` ${styles.whiteBackground} ${styles.numberInput}`}
              min={1}
              max={9223372036854776000}
              decoration={currency ?? ""}
            />
          </ThemeProvider>
          </div>
        </div>
        <div className={`col-sm-12 ${styles.customRow}`}>
          <div className={`col-sm-2 ${styles.seasonalCol}`}>
            <label>Reason Why{requiredField}</label>
          </div>
          <div className={`col-sm-6 ${styles.seasonalCol}`}>
            <ThemeProvider theme={_default}>
              <select
                value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].reason_why}
                onChange={e => handleSelectChange("reason_why",e.target.value)}
                className={` ${styles.whiteBackground} ${styles.selectType}`}
                disabled={disabled}
              >
                <option value="">Select a reason...</option>
                <option value="Vacasa Seasonal">Vacasa Seasonal</option>
                <option value="Owner Seasonal">Owner Seasonal</option>
                <option value="HOA Seasonal">HOA Seasonal</option>
              </select>
            </ThemeProvider>
          </div>
        </div>
        <div className={`col-sm-12 ${styles.customRow}`}>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <label>Start Date{requiredField}</label>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <ThemeProvider theme={_default}>
              <Input
                type="text"
                value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].rate_effective_start_date || ""}
                onChange={e => handleInputChange("rate_effective_start_date",e.target.value)}
                disabled={disabled}
                placeholder={"mm-dd"}
                customClass={`${styles.whiteBackground} ${styles.textInput}`}
              />
            </ThemeProvider>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <label>End Date </label>
          </div>
          <div className={`col-sm-3 ${styles.seasonalCol}`}>
            <ThemeProvider theme={_default}>
              <Input
                type="text"
                value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].rate_effective_end_date || ""}
                onChange={e => handleInputChange("rate_effective_end_date",e.target.value)}
                disabled={disabled}
                placeholder={"mm-dd"}
                customClass={`${styles.whiteBackground} ${styles.textInput}`}
              />
            </ThemeProvider>
          </div>
        </div>

        {hasError ?
          item.errors.map((e) => {
            return (<ThemeProvider  key={`ratesError${Math.random()}`} theme={_default}>
              <AlertMessage key={`ratesError${Math.random()}`} type="error" text={e.message}></AlertMessage>
            </ThemeProvider>)
          })
          :
          <React.Fragment/>
        }
      </div>
    </div>
  )

  return item.statusUI === "open" ? openFor : lockFor;
}