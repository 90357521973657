export enum RULE_CODE {
    CMPLNC_PERMITS = "CMPLNC-PERMITS",
    RESERVATION_CANCELATION = "RSRVCNCL",
    CANCEL_REVENUE_SHARE = "CNCLREVSHARE",
    UNIT_STAY_RESTRICTIONS = "UNITSTAYRESTRICTION",
    CHECK_IN_REQUIREMENTS = "CHECK-IN-REQUIREMENTS",
    SEASONAL_RATES = "SEASONAL-RATES"
}

export const CMPLNC_PERMITS_STANDARD_VALUE = "STANDARD";

export enum PERMISSION_RULE_RELATION {
    CHECK_IN_REQUIREMENTS = 'canEditCheckInRequirementsRule',
    UNIT_STAY_RESTRICTIONS = 'canEditUnitStayRestrictionRule',
    CANCEL_REVENUE_SHARE = 'canEditCancelRevShareRule',
    SEASONAL_RATES = 'canEditSeasonalRatesRule',
    GENERAL_EDIT_PERMISSION = 'canEdit'
}
export const TOKEN_EDIT_PERMISSIONS = [PERMISSION_RULE_RELATION.GENERAL_EDIT_PERMISSION, PERMISSION_RULE_RELATION.CANCEL_REVENUE_SHARE, PERMISSION_RULE_RELATION.UNIT_STAY_RESTRICTIONS, PERMISSION_RULE_RELATION.CHECK_IN_REQUIREMENTS, PERMISSION_RULE_RELATION.SEASONAL_RATES];
export const RULES_WITH_EXCLUSIVE_PERMISSIONS = [RULE_CODE.CANCEL_REVENUE_SHARE, RULE_CODE.UNIT_STAY_RESTRICTIONS, RULE_CODE.CHECK_IN_REQUIREMENTS, RULE_CODE.SEASONAL_RATES];
export const GENERAL_RULES_CODE = "GENERAL";

