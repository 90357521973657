import React from "react";
import {UnitRuleValuesResponse} from "../../../unitsResRules.type";
import _ from "lodash";
import styles from "./CollapseButtonComponent.module.scss";
import {Icon} from '@vacasa/react-components-lib';

interface CollapseButtonInput {
    item: UnitRuleValuesResponse;
    setFormCard: Function;
    open: boolean
}

export const CollapseButtonComponent: React.FunctionComponent<CollapseButtonInput> = (props) => {
    const {item, setFormCard, open} = props;

    const handleButtonCollapse = () => {
        const cloneCard = _.cloneDeep(item);
        if (cloneCard.statusUI === "lock")
            cloneCard.statusUI = "open";
        else
            cloneCard.statusUI = "lock";
        setFormCard(cloneCard);
    };

    return ( <button type="button" className={styles.collapseBtn} onClick={handleButtonCollapse}>
        {open === true ?
            <Icon.Eye/>
        :
            <Icon.EyeOff/>
        }
    </button> )
}