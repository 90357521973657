import React, {useState} from "react";
import {UnitRuleValuesResponse, UnitStayRestrictionValue} from "../../../../unitsResRules.type";
import stylesFormCard from "../../FormCardComponent.module.scss";
import styles from "../UnitStyrestrictionRuleComponent.module.scss";
import {AccordionItemComponent} from "../../accordionItem/AccordionItemComponent";
import _ from "lodash";
import { Input, ThemeProvider, AlertMessage, Icon } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";

interface UnitStayRestrictionItemProps {
    unitRuleValue: UnitRuleValuesResponse;
    item: UnitStayRestrictionValue;
    setItem: Function;
    index: number;
    disabled: boolean;
}

export const UnitStayRestrictionItemComponent: React.FunctionComponent<UnitStayRestrictionItemProps> = (props) => {
    const {setItem, unitRuleValue,index, disabled} = props;
    let {item} = props;
    let hasError = item.errors?.length>0;

    const getRestrictionName = () => {
        if(item.restriction_type == 'check-in')
            return "Check-ins Restrictions";
        return "Night Restrictions"
    };

    const headerInfo = <React.Fragment>{item.restriction_number} / {item.restriction_duration_type == 'days' ? item.restriction_duration : ""} {_.capitalize(item.restriction_duration_type) } | {_.capitalize(item.restriction_effective_type)} {hasError ? <Icon.AlertOctagon className={styles.headerInfoWarning} height={24} width={24}/> : ""}</React.Fragment>;

    const getAccordionHeader = () => {
        return (
            <div className={`form-row  ${styles.unitStayBoxHeader}`}>
                <AccordionItemComponent unitRuleValue={unitRuleValue} index={index} item={item} setFormCard={setItem} open={item.statusUI === "open" ? true : false} disabled={disabled} getRestrictionName={getRestrictionName} headerInfo={headerInfo}/>
            </div>
        )
    }

    const lockFor = (
        <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox} ${item.restriction_type == 'check-in' ? styles.checkInBox : styles.nigthsBox}`}>
            {getAccordionHeader()}
        </div>
    )

    const handleInputNumberChange = (name, e) => {
        let ruleClone = _.cloneDeep(unitRuleValue);
        if(e > 365) e = 365
        if (e < 1) e = 1
        e = parseInt(e.toString().replace(".", ""))
        ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e;
        setItem(ruleClone, true);
    }

    const handleInputDateChange = (name, e) => {
        let ruleClone = _.cloneDeep(unitRuleValue);
        e = e.replace(/[a-zA-Z]/g, '');
        ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e;
        setItem(ruleClone, true);
    }

    const handleSelectChange = (name, e) => {
        let ruleClone = _.cloneDeep(unitRuleValue);
        ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e;

        setItem(ruleClone, true);
    }

    const handleRadioChange = (name, e) => {
        let ruleClone = _.cloneDeep(unitRuleValue);
        ruleClone.attributes.unit_rule_value_attributes.enrolled[index][name] = e.value;

        if(name=='restriction_effective_type' && e.value == 'always'){
            ruleClone.attributes.unit_rule_value_attributes.enrolled[index]["restriction_effective_start_date"]=null
            ruleClone.attributes.unit_rule_value_attributes.enrolled[index]["restriction_effective_end_date"]=null
        }
        setItem(ruleClone, true);
    }

    const getAllowedNumberLabel = () => {
        if(item.restriction_type == 'check-in')
            return "Number of check-ins allowed";
        return "Number of nights allowed"
    };

    const openFor = (
        <div className={`${stylesFormCard.littleFont}  ${stylesFormCard.ruleItem} ${styles.unitStayBox} ${item.restriction_type == 'check-in' ? styles.checkInBox : styles.nigthsBox}`}>
            {getAccordionHeader()}
            <div className={"form-row"}>
                <div className={`col-sm-12 ${styles.customRow} ${styles.allowedReservations}`}>
                    <div className={"col-sm-8"} style={{paddingLeft: 0}}>
                        <label><strong>{getAllowedNumberLabel()}</strong></label>
                    </div><br/>
                    <div className={` ${styles.inputBox}`}>
                        <div className={`col-sm-2 ${styles.restrictionInputs}`} style={{paddingRight: 5}}>
                            <ThemeProvider theme={_default}>
                                <Input
                                    type="number"
                                    value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_number || 1}
                                    onChange={e => handleInputNumberChange("restriction_number",+e.target.value)}
                                    disabled={disabled}
                                    customClass={` ${styles.whiteBackground} ${styles.numberInput}`}
                                    min={1}
                                    max={365}
                                />
                            </ThemeProvider>
                        </div>
                        <div className={`col-sm-1 ${styles.restrictionInputs} ${styles.separators}`} style={{paddingRight: 5}}>
                           per
                        </div>
                        <div className={`col-sm-3 ${styles.restrictionInputs}`} style={{paddingRight: 5}}>
                            <ThemeProvider theme={_default}>
                                <select
                                    value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_duration_type}
                                    onChange={e => handleSelectChange("restriction_duration_type",e.target.value)}
                                    className={` ${styles.whiteBackground} ${styles.selectType}`}
                                    disabled={disabled}
                                >
                                    <option value="year">Year</option>
                                    <option value="month">Month</option>
                                    <option value="days">Days</option>
                                </select>
                            </ThemeProvider>
                        </div>
                        {unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_duration_type == 'days' ?
                            <React.Fragment>
                                <div className={`col-sm-2 ${styles.restrictionInputs}`} style={{paddingRight: 0}}>
                                    <ThemeProvider theme={_default}>
                                        <Input
                                            type="number"
                                            value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_duration || ""}
                                            onChange={e => handleInputNumberChange("restriction_duration",+e.target.value)}
                                            disabled={disabled}
                                            customClass={` ${styles.whiteBackground} ${styles.numberInput}`}
                                            min={1}
                                            max={365}
                                        />
                                    </ThemeProvider>
                                </div>
                            </React.Fragment>
                            : <React.Fragment/>
                        }

                        {hasError ?
                                item.errors.map((e, i) => {
                                    return (<ThemeProvider  key={`restrictionError${Math.random()}`} theme={_default}>
                                        <AlertMessage key={`restrictionError${Math.random()}`} type="error" text={e.message}></AlertMessage>
                                    </ThemeProvider>)
                                })
                                 :
                            <React.Fragment/>
                        }

                    </div>
                </div>
                <div className={`col-sm-12 ${styles.customRow} ${styles.effectiveDates}`}>
                    <div className={"col-sm-8"} style={{paddingLeft: 0}}>
                        <label><strong>Restriction Effective Dates</strong></label>
                    </div><br/>
                    <div className={` ${styles.inputBox}`}>
                        <React.Fragment>
                            <ThemeProvider theme={_default}>
                                <div className={styles.radio} onChange={e => handleRadioChange("restriction_effective_type",e.target)}>
                                    <div  className={`${styles.radioBox}`}>
                                        <span className={`${styles.radioLabel}`} >Always</span>
                                        <input className={styles.radioInput} type="radio" value="always" name={`restriction_effective_type${index}`} disabled={disabled}  defaultChecked={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_effective_type === "always"} />
                                    </div>
                                    <div  className={`${styles.radioBox}`}>
                                        <span className={`${styles.radioLabel}`} >Custom</span>
                                        <input className={styles.radioInput} type="radio" value="custom" disabled={disabled} name={`restriction_effective_type${index}`} defaultChecked={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_effective_type === "custom"}/>
                                    </div>
                                </div>
                            </ThemeProvider>
                        </React.Fragment>

                        {unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_effective_type == 'custom' ?
                            <React.Fragment>
                                <ThemeProvider theme={_default}>
                                    <div className={`${styles.datesInputs}`}>

                                        <Input
                                            type="text"
                                            value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_effective_start_date || ""}
                                            onChange={e => handleInputDateChange("restriction_effective_start_date",e.target.value)}
                                            disabled={disabled}
                                            customClass={` ${styles.whiteBackground}`}
                                            placeholder={"mm-dd"}
                                        />
                                    </div>
                                    <div className={`${styles.restrictionInputs} ${styles.separators}`} style={{paddingRight: 5}}>
                                        to
                                    </div>
                                    <div className={`${styles.datesInputs}`}>
                                        <Input
                                            type="text"
                                            value={unitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].restriction_effective_end_date|| ""}
                                            onChange={e => handleInputDateChange("restriction_effective_end_date",e.target.value)}
                                            disabled={disabled}
                                            customClass={` ${styles.whiteBackground}`}
                                            placeholder={"mm-dd"}
                                        />
                                    </div>

                                </ThemeProvider>
                            </React.Fragment>
                            : <React.Fragment/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
    return item.statusUI === "open" ? openFor : lockFor;
}