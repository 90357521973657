import {GENERAL_RULES_CODE, PERMISSION_RULE_RELATION, RULE_CODE, TOKEN_EDIT_PERMISSIONS} from "../constants";

export const userCanEdit = (token) => {
    let canEdit = [];
    TOKEN_EDIT_PERMISSIONS.map(permission => {
        if (permission in token && token[permission]) {
            if (permission === PERMISSION_RULE_RELATION.GENERAL_EDIT_PERMISSION)
                canEdit.push(GENERAL_RULES_CODE);
            else {
                const indexOfPRR = Object.values(PERMISSION_RULE_RELATION).indexOf(permission);
                const key = Object.keys(PERMISSION_RULE_RELATION)[indexOfPRR];
                canEdit.push( RULE_CODE[key]);
            }
        }
    });
    return canEdit;
}