import React from 'react';
import {UnitRuleValuesResponse} from "../../unitsResRules.type";
import {UnitRuleValuesSummary} from "../unitRuleValuesSummary/UnitRuleValuesSummary";
import styles from "../unitRuleValuesSummary/UnitRuleValuesSummary.module.scss";

interface ContentSummaryProps {
    unitRuleValues: Array<UnitRuleValuesResponse>;
}

export const ContentSummary: React.FunctionComponent<ContentSummaryProps> = (props) => {
    const {unitRuleValues} = props;

    const renderTableHeader = (): JSX.Element => {
        return (
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" className={styles.lightHeader}>
                        Rule Name
                    </th>
                    <th scope="col" className={styles.lightHeader}>
                        Value
                    </th>
                </tr>
            </thead>
        );
    };

    return (
        <React.Fragment>
            <div className={`${styles.valuesTable} row`}></div>
            <div className="container-fluid col-md-12">
                <div className={`${styles.valuesTable} col-md-6`}>
                    <h4>Default Value Rules</h4>
                    <table className={`table ${styles.tableMidnight} ${styles.roundedTable}`}>
                        {renderTableHeader()}
                        <tbody>
                        {unitRuleValues.map((unitRuleValue, index) => {
                            return unitRuleValue.id ? "" : <UnitRuleValuesSummary unitRuleValue={unitRuleValue} key={index}/>;
                        })}
                        </tbody>
                    </table>

                </div>
                <div className={`${styles.valuesTable}  col-md-6`}>
                    <h4>Custom Value Rules</h4>
                    <table className={`table ${styles.tableMidnight} ${styles.roundedTable}`}>
                        {renderTableHeader()}
                        <tbody>
                            {unitRuleValues.map((unitRuleValue, index) => {
                                return unitRuleValue.id ? <UnitRuleValuesSummary unitRuleValue={unitRuleValue} key={index} /> : "";
                            })}
                        </tbody>
                    </table>

                </div>
            </div>
        </React.Fragment>
    );
};
