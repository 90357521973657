import React from "react";
import { Button, ThemeProvider } from "@vacasa/react-components-lib";
import theme from '@vacasa/react-components-lib/lib/themes/default';
import {authManager} from "@common/authentication/lib/src/services/auth/auth-manager";
import {userCanEdit} from "../../../utils/utils";

interface HeaderSummaryProps {}

export const HeaderSummary: React.FunctionComponent<HeaderSummaryProps> = (props) => {

    const info = authManager.getInfoFromAdmin<any>();
    const canEdit = userCanEdit(info).length > 0;

    const handleOpenDetailClick = () => {
        window.parent.postMessage({action: 'click', type: 'showShelf'}, '*');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-auto">
                    <h2>Unit-Specific Reservation Rules</h2>
                </div>
                <div className="col-auto">
                    <ThemeProvider theme={theme}>
                        {canEdit ?
                            <Button
                                variant={"secondary"}
                                onClick={handleOpenDetailClick}
                                disabled={false}
                            >Assign Rules</Button>
                        :
                            <Button
                                variant={"secondary"}
                                onClick={handleOpenDetailClick}
                                disabled={false}
                            >{"View Rules"}</Button>
                        }
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};
