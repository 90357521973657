import React from "react";
import { SeasonalRatesValue, UnitRuleValuesResponse, UnitStayRestrictionValue } from "../../../unitsResRules.type";
import _ from "lodash";
import styles from "./AccordionItemComponent.module.scss";
import {Icon} from '@vacasa/react-components-lib';

interface AccordionItemInput {
    unitRuleValue: UnitRuleValuesResponse;
    item: UnitStayRestrictionValue | SeasonalRatesValue;
    index: number;
    setFormCard: Function;
    open: boolean;
    disabled: boolean;
    getRestrictionName: Function;
    headerInfo?: JSX.Element;
}

export const AccordionItemComponent: React.FunctionComponent<AccordionItemInput> = (props) => {
    const {setFormCard, unitRuleValue, index, disabled, getRestrictionName, headerInfo} = props;
    let {open} = props;

    const handleButtonCollapse = () => {
        const cloneUnitRuleValue= _.cloneDeep(unitRuleValue);
        if (cloneUnitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].statusUI === "lock")
            cloneUnitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].statusUI = "open";
        else
            cloneUnitRuleValue.attributes.unit_rule_value_attributes.enrolled[index].statusUI = "lock";
        setFormCard(cloneUnitRuleValue)

    };

    const handleButtonTrash = () => {
        const cloneUnitRuleValue= _.cloneDeep(unitRuleValue);
        cloneUnitRuleValue.attributes.unit_rule_value_attributes.enrolled.splice(index, 1);
        setFormCard(cloneUnitRuleValue, true)
    };

    return (
        <div className={`col`}>
            {open === true ?
                <React.Fragment>
                    <Icon.ChevronDown onClick={handleButtonCollapse} className={styles.restrictionArrow} height={24} width={24}/>
                </React.Fragment>
                :  <React.Fragment>
                    <Icon.ChevronRight onClick={handleButtonCollapse} className={styles.restrictionArrow} height={24} width={24}/>
                </React.Fragment>}


            <h6  onClick={handleButtonCollapse} className={`${styles.title}`}><strong><a>{getRestrictionName()}</a></strong></h6>

            {open === true && !disabled ?
                <React.Fragment>
                    <Icon.Trash2 onClick={handleButtonTrash} className={styles.headerInfo} height={24} width={24}/>
                </React.Fragment>
                :

                <React.Fragment>
                    <span className={`${styles.headerInfo}`}>{headerInfo}</span>
                </React.Fragment>}
        </div>
    )
}