import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {CardListComponent} from "../components/cardList/CardListComponent";
import {UnitRuleValuesPreviewApp} from "../components/unitRuleValuesPreview/UnitRuleValuesPreviewApp";

class RouterApp extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/details" component={CardListComponent} />
                    <Route path="/" component={UnitRuleValuesPreviewApp} />
                </Switch>
            </Router>
        );
    }
}

export default RouterApp;
