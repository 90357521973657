import React from "react";
import {UnitRuleValuesResponse,Value} from "../../../../unitsResRules.type";

interface SecondarySelectInput {
    selectedItem: UnitRuleValuesResponse;
    secondary: string;
    secondaryValue: string;
    handleChange: Function;
    secondListCollection: Array<Value>;
    disabled: boolean;
    label: string;
}

export const SecondarySelectComponent: React.FunctionComponent<SecondarySelectInput> = (props) => {

    const {selectedItem, secondary, secondaryValue, secondListCollection, handleChange, disabled, label } = props;

    return (
        <div className="form-row">
            <div className="col">
                <div className="form-group">
                    <label><strong>{label}</strong></label>
                    <select
                        className="form-control"
                        id={`valueCode_${selectedItem.index}`}
                        value={`${secondaryValue}`}
                        onChange={(e) => handleChange(secondary, e)}
                        disabled={disabled}
                    >
                        {secondListCollection.map((item: Value, index) => {
                            return (
                                <option
                                    key={index}
                                    value={`${item.code}`}
                                >
                                    {item.code}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}